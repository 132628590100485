import React, { useEffect } from 'react'

const NotFound = () => {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.location.href = '/app/'
    }
  }, [])

  return <></>
}

export default NotFound
